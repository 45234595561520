

















































































import ChannelInviteLinksSettings from '@/views/invite-links/ChannelInviteLinksSettings.vue'
import {
  getInviteLinksStat,
  prepareSimpleSeriesForLinks,
  prepareInviteLinks
} from '@/includes/logic/InviteLinks/InviteLinksHelper'
import { errorNotification } from '@/includes/services/NotificationService'
import { InviteLinksWithStat, LocalInviteLink, InviteLinkWithKey } from '@/includes/logic/InviteLinks/types'

import List from 'piramis-base-components/src/components/Pi/components/List/List.vue'
import { ListDirection } from 'piramis-base-components/src/components/Pi/components/List/logic/types'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import LinkCard from 'piramis-base-components/src/shared/modules/inviteLinks/LinkCard.vue'

import { max, sumBy } from 'lodash'
import { ExportToCsv } from 'export-to-csv'
import { Component } from 'vue-property-decorator'
import Vue from "vue";

@Component({
  'components': {
    LinkCard,
    PageTitle,
    List,
    ChannelInviteLinksSettings,
    ConfigField
  },
  data() {
    return {
      ListDirection
    }
  },
})
export default class LinkList extends Vue {
  inviteLinks: Array<LocalInviteLink> | null = null

  isLoading = false

  maxValue: number | null = null

  gotoLinkPage(actionType: 'new' | 'edit', query?: Record<string, string>): void {
    this.$router.push({
      name: 'invite_link',
      params: {
        actionType
      },
      query
    })
  }

  linkSearch(item: LocalInviteLink & { disable_auto_attack: boolean, expire: string }, index: number, text: string) {
    return item.data.tags && item.data.tags.some(t => t.includes(text)) || item.data.title.includes(text)
  }

  onEditLink(key: InviteLinkWithKey['key']): void {
    this.gotoLinkPage('edit', { key })
  }

  onDeleteLink(key: InviteLinkWithKey['key']): void {
    this.$confirm({
      title: this.$t('link_list_popup_link_remove_text').toString(),
      okText: this.$t('accept').toString(),
      cancelText: this.$t('reject').toString(),
      onOk: () => this.deleteLink(key),
      centered: true,
    })
  }

  onStatClick(key: InviteLinkWithKey['key']) {
    this.$router.push({
      name: 'Invite_links_statistics',
      query: {
        key: key.split('.')[0]
      }
    })
  }

  deleteLink(key: InviteLinkWithKey['key']): void {
    this.$store.dispatch('deleteInviteLink', key)
      .then((res) => {
        if (res && this.inviteLinks) {
          this.inviteLinks = this.inviteLinks.filter(link => link.data.key !== key)
        }
      })
  }

  exportToCsv(links: Array<LocalInviteLink>): void {
    const options = {
      'fieldSeparator': ';',
      'quoteStrings': '"',
      'decimalSeparator': '.',
      'showLabels': true,
      'showTitle': true,
      'filename': this.$t('links_csv_table_title').toString(),
      'title': this.$t('links_csv_table_title').toString(),
      'useTextFile': false,
      'useBom': true,
      'useKeysAsHeaders': true,
      'headers': undefined,
    }

    const data = links.map((link) => {
      const { data } = link

      return {
        [this.$t('links_csv_title').toString()]: data.title,
        [this.$t('links_csv_approve').toString()]: this.$t(`link_approve_type_${ data.config.approve.toLocaleLowerCase() }`).toString(),
        [this.$t('links_csv_disable_auto_attack').toString()]: this.$t(`links_csv_disable_auto_attack_${ data.config.disable_auto_attack }`).toString(),
        [this.$t('links_csv_enter').toString()]: data.enter,
        [this.$t('links_csv_leave').toString()]: data.leave,
        [this.$t('links_csv_tags').toString()]: data.tags ? data.tags.join(', ') : '-',
        [this.$t('field_invite_link_model_price_title').toString()]: data.config.price,
        [this.$t('links_csv_date').toString()]: data.config.expire ?? '-'
      }
    })

    new ExportToCsv(options).generateCsv(data)
  }

  get sortedInviteLinks() {
    if (this.inviteLinks) {
      return this.inviteLinks
        .slice()
        .sort((a, b) => {
          if (a.items && b.items) {
            return sumBy(b.items, 'first_enter') - sumBy(a.items, 'first_enter') ||
              sumBy(b.items, 'first_leave') - sumBy(a.items, 'first_leave')
          } else if (!!a.items && !b.items) {
            return -1
          } else if (!a.items && !!b.items) {
            return 1
          } else {
            return b.data.enter - a.data.enter || b.data.kick - a.data.kick
          }
        })
    }

    return []
  }

  setMaxValue(stat: InviteLinksWithStat) {
    const maxValue = max(stat.map(l => (l.items ?? []).map(s => [ s.first_enter, s.first_leave ]).flat()).flat())

    if (maxValue !== undefined) {
      this.maxValue = maxValue
    }
  }

  getLinks() {
    this.isLoading = true

    this.$store.dispatch('getInviteLinks')
      .then(links => {
        this.$store.commit('SET_INVITE_LINKS_TO_STORE', links)

        getInviteLinksStat(
          this.$store.getters.activeBoard.board,
          this.$store.state.channelsState.activeChannel.id,
          { interval: { interval_type: 'Short', } }
        )
          .then((stat) => {
            this.setMaxValue(stat)

            return stat
          })
          .then((stat) => {
            prepareSimpleSeriesForLinks(prepareInviteLinks(links, stat), this.$i18n)
              .then(res => this.inviteLinks = res)
              .catch((e) => {
                errorNotification(e)

                this.inviteLinks = []
              })
              .finally(() => {
                this.isLoading = false
              })
          })
      })
  }

  mounted() {
    this.$store.dispatch('AdminTemplate/hideSaveConfigButton')

    this.getLinks()
  }
}

